<template>
  <div class="w-100 bg-white p-sm-3">
    <div class="container">
      <!-- whiteShadow -->
      <b-row class="verticalSpacing">
        <b-col cols="12" lg="6" class="text-md-left text-center align-items-center">
          <!-- Ready to experience a new way of buying cars internationally? -->
          <span class="experience">{{ $t("Buy.Content.ReadyToExperience") }}</span>
        </b-col>
        <b-col cols="12" lg="6" class="row mt-xl-0 mx-auto">
          <b-col cols="12" md="6" class="my-auto start-now-wrapper">
            <router-link to="search" tag="span">
              <b-button variant="primary" pill class="buttonText">
                {{ $t("Buy.Content.StartNow") }}
              </b-button>
            </router-link>
          </b-col>
          <b-col cols="12" md="6" class="my-auto">
            <b-button
              variant="outline-primary"
              pill
              class="buttonText"
              :to="{ name: 'ContactReps' }"
            >
              <!-- Contact Sales -->
              {{ $t("Buy.Content.ContactSales") }}
            </b-button>
          </b-col>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: Array,
    },
  },
  methods: {
    showText(key) {
      let t;
      if (this.text) {
        this.text.forEach((element) => {
          if (element.Key === key) {
            t = element.Value;
          }
        });
      }
      return t;
    },
  },
};
</script>

<style scoped>
.verticalSpacing {
  padding-top: 63px;
  padding-bottom: 63px;
}
.experience {
  font-weight: 600;
  font-size: 34px;
  line-height: 48px;
}
.buttonText {
  padding: 16px 40px;
  width: 100%;
}
.bg-white {
  margin-top: 50px;
}
@media (max-width: 1200px) {
  .experience {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
  .verticalSpacing {
    padding-top: 33px;
    padding-bottom: 33px;
  }
}

@media (max-width: 991px) {
  .experience {
    margin-bottom: 24px;
    display: block;
  }
}
@media (max-width: 767px) {
  .start-now-wrapper {
    margin-bottom: 16px !important;
  }
}
@media (max-width: 576px) {
  .experience {
    font-size: 20px;
    line-height: 28px;
  }
  .buttonText {
    padding: 12px 24px;
  }
  .bg-white {
    margin-top: 24px;
  }
}
</style>
